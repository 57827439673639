import Head from "next/head";
import { useState } from "react";
import { useRouter } from "next/router";
import Source from "components/source";
import { signIn, useSession } from "next-auth/react";

export default function New() {
	const [redirect, setRedirect] = useState(false);
	const router = useRouter();
	const { query } = router;

	const { status } = useSession();
	if (status === "authenticated") router.push((query.redirect as string) || "/dashboard");

	return (
		<div className="">
			<Head>
				<title>John Jay Robotics Inventory Software</title>
				<Source />
			</Head>
			<div className="min-h-screen bg-gray-900 py-20">
				<div className="mx-auto my-10 flex max-w-sm rounded-lg bg-gray-800 shadow-lg md:my-24 lg:max-w-4xl">
					<div
						className="hidden bg-cover lg:block lg:w-1/2"
						style={{
							backgroundImage:
								"url('https://cdn.discordapp.com/attachments/769275863786455057/913234503617626182/Tempfile.png')",
						}}
					></div>
					<div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
						<h2 className="text-center text-2xl font-semibold text-white">Inventory Software</h2>
						<p className="text-center text-lg text-gray-200">Made by Anish and Nash. </p>
						{!redirect && (
							<>
								<a
									onClick={() => {
										signIn("google");
										setRedirect(true);
									}}
									className="mt-4 flex items-center justify-center rounded-lg border border-gray-700 bg-gray-700 text-gray-200 hover:bg-gray-600"
								>
									<div className="px-4 py-2">
										<svg className="h-6 w-6" viewBox="0 0 40 40">
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#FFC107"
											/>
											<path
												d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
												fill="#FF3D00"
											/>
											<path
												d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
												fill="#4CAF50"
											/>
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#1976D2"
											/>
										</svg>
									</div>
									<span className="w-5/6 px-4 py-3 text-center font-bold">Sign in with Google</span>
								</a>
							</>
						)}
						{redirect && (
							<>
								<a className="mt-4 flex cursor-wait items-center justify-center rounded-lg border border-gray-700 bg-gray-700 text-gray-200 hover:bg-gray-600">
									<span className="inline-flex w-5/6 justify-center px-4 py-3 text-center font-bold">
										<svg
											className="h-5 w-5 animate-spin text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												className="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												strokeWidth="4"
											></circle>
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
									</span>
								</a>
							</>
						)}

						<div className="mt-4 flex items-center justify-between">
							<span className="w-1/5 border-b border-gray-600 lg:w-1/3"></span>
							<a className="text-center text-xs uppercase text-gray-400">Other Links</a>
							<span className="w-1/5 border-b border-gray-400 lg:w-1/3"></span>
						</div>

						<div className="mt-4">
							<button className="w-full transform rounded bg-gray-700 px-4 py-2 tracking-wide text-white transition-colors duration-200 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none">
								More About John Jay Robotics
							</button>
							<button className="mt-2 w-full transform rounded bg-gray-700 px-4 py-2 tracking-wide text-white transition-colors duration-200 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none">
								Join the Jaybots?
							</button>
							<button className="mt-2 w-full transform rounded bg-gray-700 px-4 py-2 tracking-wide text-white transition-colors duration-200 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none">
								Jaybots Booster Club
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
