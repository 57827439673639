export default function source() {
	return (
		<>
			<link rel="icon" href="https://cdn.jaybots.org/logo/logo.png" />
			<meta name="theme-color" content="#111827" />
			<meta name="description" content="Inventory software for the John Jay Robotics Club." />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content="Inventory - John Jay Robotics Club" />
			<meta name="twitter:description" content="Inventory software for the John Jay Robotics Club." />
			<meta property="twitter:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:title" content="Inventory - John Jay Robotics Club" />
			<meta property="og:description" content="Inventory software for the John Jay Robotics Club." />
			<meta property="og:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:site_name" content="John Jay Robotics Club" />
		</>
	);
}
